import React, {useState} from 'react'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';

function Footer() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        // console.log(formData)
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://email-repo.onrender.com/enquire', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({
                formData
              }),
            });
      
            const result = await response.json();
            console.log(result.message);
            toast.success(result.message, {
                position: "top-center"
            })

            
          } catch (error) {
            console.log('Error sending the contact form:', error);
            toast.error('An error occurred while sending the email. Please try again later.',{
                position: "top-center"
            });
        }

        
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    
    return (
        <footer>
            <ToastContainer />
            <section className='top_footer'>
                <div className='container'>
                    <div className='top_footer_flex'>
                        <div className='top_footer_left'>
                            <h2><span>Get in </span>touch</h2>
                            <p>
                                As a freelance web developer, UX designer, and graphic artist, I embark on a mission to transform ideas into visually stunning and functionally seamless realities. With a foundation in web development, I code with precision, bringing websites to life that not only meet but exceed modern standards.
                            </p>
                            <div className='top_footer_contact_info'>
                                <div className='contact_details'>
                                    <h3>Contact Info</h3>
                                    <div className='mobile_email'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M16.75 2h-10c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2m-10 18V4h10l.002 16z" /><circle cx="11.75" cy="18" r="1" fill="currentColor" /></svg>
                                        <Link to='tel:+91 8828181399'>+91 8828181399</Link>
                                    </div>
                                    <div className='mobile_email'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2zm-2 0l-8 4.99L4 6zm0 12H4V8l8 5l8-5z" /></svg>
                                        <Link to='mailto:swapnildarge65@gmail.com'>swapnildarge65@gmail.com</Link>
                                    </div>
                                </div>
                                <div className='address_info'>
                                    <h3>Address</h3>
                                    <p>
                                        14/1406, City View Tower, <br />
                                        S.N Path, Lower Parel (West), <br />
                                        Mumbai - 400013
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='top_footer_right'>
                            <h2>Send Message</h2>
                            <form onSubmit={handleSubmit}>
                                <div className='form_element'>
                                    <input type='text' placeholder='Full Name' name='name' id='name' onChange={handleChange} />
                                </div>
                                <div className='form_element'>
                                    <input type='email' placeholder='Email' name='email' id='email' onChange={handleChange} />
                                </div>
                                <div className='form_element'>
                                    <input type='text' placeholder='Subject' name='subject' id='subject' onChange={handleChange} />
                                </div>
                                <div className='form_element'>
                                    <textarea type='text' placeholder='Message' name='message' id='message' rows={5} onChange={handleChange} />
                                </div>
                                <button type='submit' className='redirection_link'>
                                    <span>Send Message</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" d="M16 4L6 14l1.41 1.41L15 7.83V28h2V7.83l7.59 7.58L26 14z" /></svg>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section className='bottom_footer'>
                <div className='container'>
                    <div className='footer_logo'>
                        <Link to="/">
                            <img src='images/logo.png' alt='Swapnil Darge' />
                        </Link>
                    </div>

                    <div className='footer_nav'>
                        <div className='footer_menu'>
                            <nav>
                                <ul>
                                    <li><Link to="/" onClick={scrollToTop}>Home</Link></li>
                                    <li><Link to="/about-me" onClick={scrollToTop}>About Me</Link></li>
                                    <li><Link to="/services" onClick={scrollToTop}>Services</Link></li>
                                    <li><Link to="/portfolio" onClick={scrollToTop}>Portfolio</Link></li>
                                    <li><Link to="/blogs" onClick={scrollToTop}>Blogs</Link></li>
                                    <li><Link to="/contact-us" onClick={scrollToTop}>Contact</Link></li>
                                </ul>
                            </nav>
                        </div>
                        <div className='footer_copyright'>
                            <p><span>&copy; 2024. Made with </span><img src='images/heart.png' alt='heart' /><span> by Swapnil Darge.</span></p>
                        </div>
                    </div>
                </div>
            </section>
        </footer>
    )
}

export default Footer