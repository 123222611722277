import React, {useEffect, useState} from 'react'
import Header from '../shared/Header'
import Footer from '../shared/Footer'
import Contact from '../shared/Contact'
import VerticalLines from '../shared/VerticalLines'
import { Link } from 'react-router-dom'
import Loading from '../shared/Loading'

function Services() {
  const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      // Simulate an API call
      setTimeout(() => {
        setIsLoading(false);
      }, 3500);
    }, []);
  
    if (isLoading) {
      return <Loading  />;
    }

  return (
    <>
      <VerticalLines />
      <Header />
      <section className='page_banner_area'>
        <img src='images/service_banner_bg.jpg' alt='page_banner' className='banner_img_bg' />
        <div className='page_banner_content'>
          <h2 className='wow animate__animated animate__fadeInUp'>
            Experience-Driven <br />
            <span>Services</span>
          </h2>
          <p className='wow animate__animated animate__fadeInUp animate__delay-1s'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m21.66 10.25l-9-8a1 1 0 0 0-1.32 0l-9 8a1 1 0 0 0-.27 1.11A1 1 0 0 0 3 12h1v9a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-9h1a1 1 0 0 0 .93-.64a1 1 0 0 0-.27-1.11M13 20h-2v-3a1 1 0 0 1 2 0Zm5 0h-3v-3a3 3 0 0 0-6 0v3H6v-8h12ZM5.63 10L12 4.34L18.37 10Z" /></svg>
            <Link to='/'>Home</Link>
            <span className='slash'>/</span>
            <span>Services</span>
          </p>
        </div>
      </section>
      <section className='service_content_area'>
        <div className='container'>
          <h2 className='sec_title wow animate__animated animate__fadeInUp'>
            Architecting Your Digital Destiny: <br />
            <span>Expandable, Safe, Pioneering</span>
          </h2>
          <div className='services_flex'>
            <div className='services_col wow animate__animated animate__fadeInUp'>
              <div className='service_box'>
                <div className='service_img'>
                  <img src='images/inner_staticdevelopment_img.jpeg' alt='Static Web Development' />
                </div>
                <div className='sevice_info'>
                  <h3>
                    <span>.01</span>
                    Static Web Development
                  </h3>
                  <p>
                  Static web development refers to the creation of websites that consist of fixed, unchanging content. Unlike dynamic websites that retrieve data from a database and generate content on the fly, static websites are pre-built and display the same information to all users. The content on a static site remains constant until manually updated by a developer.
                  </p>
                  <Link to='/static-web-development' className='service_link'>Read More</Link>
                </div>
              </div>
            </div>
            <div className='services_col wow animate__animated animate__fadeInUp'>
              <div className='service_box'>
                <div className='service_img'>
                  <img src='images/inner_dynamicdevelopment_img.jpeg' alt='Static Web Development' />
                </div>
                <div className='sevice_info'>
                  <h3>
                    <span>.02</span>
                    Dynamic Web Development
                  </h3>
                  <p>
                  Dynamic web development involves creating websites that generate content in real-time, responding to user interactions and input. Unlike static websites, dynamic websites can retrieve and display data from databases, enabling personalized and interactive experiences for users.
                  </p>
                  <Link to='/dynamic-web-development' className='service_link'>Read More</Link>
                </div>
              </div>
            </div>
            <div className='services_col wow animate__animated animate__fadeInUp'>
              <div className='service_box'>
                <div className='service_img'>
                  <img src='images/inner_wordpress_img.jpeg' alt='Static Web Development' />
                </div>
                <div className='sevice_info'>
                  <h3>
                    <span>.03</span>
                    Wordpress Development
                  </h3>
                  <p>
                  Wordpress development refers to the process of creating and customizing websites using the WordPress content management system (CMS). WordPress is a popular and versatile platform that powers a significant portion of the internet, ranging from personal blogs to complex corporate websites and e-commerce platforms.
                  </p>
                  <Link to='/wordpress-development' className='service_link'>Read More</Link>
                </div>
              </div>
            </div>
            <div className='services_col wow animate__animated animate__fadeInUp'>
              <div className='service_box'>
                <div className='service_img'>
                  <img src='images/inner_uiux_img.jpeg' alt='Static Web Development' />
                </div>
                <div className='sevice_info'>
                  <h3>
                    <span>.04</span>
                    UI/UX Design
                  </h3>
                  <p>
                  UI design deals with the look and feel of a product, UX design is about the overall experience, ensuring that the product is easy to use, meets users' needs, and provides a positive interaction. Both UI and UX design are crucial for the success of digital products, and effective collaboration between UI and UX designers leads to well-designed and user-friendly experiences.
                  </p>
                  <Link to='/uiux-design' className='service_link'>Read More</Link>
                </div>
              </div>
            </div>
            <div className='services_col wow animate__animated animate__fadeInUp'>
              <div className='service_box'>
                <div className='service_img'>
                  <img src='images/inner_graphic_design_img.jpeg' alt='Static Web Development' />
                </div>
                <div className='sevice_info'>
                  <h3>
                    <span>.05</span>
                    Graphic Design
                  </h3>
                  <p>
                  Graphic design plays a crucial role in effective communication, helping to convey messages, evoke emotions, and create memorable experiences. Whether it's designing a company logo, creating a poster for an event, or developing the visual components of a website, graphic designers use their artistic skills and knowledge of design principles to produce visually compelling and impactful work.
                  </p>
                  <Link to='/graphic-design' className='service_link'>Read More</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Contact name="innerPageAbout" />
      <Footer />
    </>
  )
}

export default Services