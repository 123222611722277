import React, {useEffect, useState} from 'react'
import Loading from '../../shared/Loading'
import Header from '../../shared/Header'
import Footer from '../../shared/Footer'
import Contact from '../../shared/Contact'
import { Link } from 'react-router-dom'

function DynamicWebDevelopment() {
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      // Simulate an API call
      setTimeout(() => {
        setIsLoading(false);
      }, 3500);
    }, []);
  
    if (isLoading) {
      return <Loading  />;
    }
    return (
        <>
            <Header />
            <section className='page_banner_area'>
                <img src='images/inner_service_banner.jpg' alt='page_banner' className='banner_img_bg' />
                <div className='page_banner_content'>
                    <h2>
                        <span>Dynamic Web</span><br />
                        Development
                    </h2>
                    <p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m21.66 10.25l-9-8a1 1 0 0 0-1.32 0l-9 8a1 1 0 0 0-.27 1.11A1 1 0 0 0 3 12h1v9a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-9h1a1 1 0 0 0 .93-.64a1 1 0 0 0-.27-1.11M13 20h-2v-3a1 1 0 0 1 2 0Zm5 0h-3v-3a3 3 0 0 0-6 0v3H6v-8h12ZM5.63 10L12 4.34L18.37 10Z" /></svg>
                        <Link to='/'>Home</Link>
                        <span className='slash'>/</span>
                        <Link to='/services'>Services</Link>
                        <span className='slash'>/</span>
                        <span>Dynamic Web Development</span>
                    </p>
                </div>
            </section>
            <section className='all_service_page_content section_padding'>
                <div className='container'>
                    <div className='all_service_inner_image'>
                        <img src="images/inner_dynamicdevelopment_img.jpeg" />
                    </div>
                    <div className='all_service_inner_para'>
                        <p>
                        Dynamic web development involves creating websites that generate content in real-time, responding to user interactions and input. Unlike static websites, dynamic websites can retrieve and display data from databases, enabling personalized and interactive experiences for users. Dynamic web development relies on server-side scripting, databases, and client-side scripting to deliver content that can change based on user actions.
                        <br />
                        <br />
                        Dynamic web development is the art of crafting websites that come alive with real-time interactivity and personalized experiences. Unlike static counterparts, dynamic websites harness the power of server-side scripting and databases to deliver content that adapts and evolves based on user actions. This approach enables a wide range of functionalities, from user authentication and interactive forms to seamless e-commerce transactions. In the realm of dynamic web development, the server takes the lead, processing requests, interacting with databases, and dynamically generating content that responds to the unique needs and preferences of each user. This dynamic nature allows for the creation of feature-rich applications that engage visitors, providing not just information but immersive, user-centric experiences. Whether it's updating content on-the-fly, managing vast amounts of data, or facilitating complex interactions, dynamic web development lays the foundation for a web ecosystem that is both responsive and scalable, meeting the demands of today's interactive and ever-evolving online landscape.

                        </p>
                    </div>
                </div>
            </section>
            <Contact name="innerPageAbout" />
            <Footer />
        </>
    )
}

export default DynamicWebDevelopment