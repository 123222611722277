import React from 'react'

function VerticalLines() {
  return (
    <section className='vertical_line_flex'>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
    </section>
  )
}

export default VerticalLines