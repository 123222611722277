import React from 'react'
import Header from '../shared/Header'
import { Swiper, SwiperSlide } from 'swiper/react';
import Marquee from '../shared/Marquee';
import Contact from '../shared/Contact';
import Footer from '../shared/Footer';
import SectionTitle from '../shared/SectionTitle';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
// import required modules
import { Autoplay, Pagination, EffectFade } from 'swiper/modules';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import VerticalLines from '../shared/VerticalLines';
import { Link } from 'react-router-dom';
import Loading from '../shared/Loading';
import { useEffect, useState } from 'react';

function Home() {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };


  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 3500);
  }, []);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <VerticalLines />
      <Header />
      <section className='home_banner_slider'>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 10000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        effect={'fade'}
        navigation={true}
        modules={[Autoplay, Pagination]}
        className="heroSwipper"
      >
        <SwiperSlide>
            <img src='images/banner_01.jpg' alt='banner_01' />
            <h2 className='home_banner_text'>
              <span>Code, Create, Captivate </span>
              <span>Unleashing the Power of</span>
              <span> Web Development</span>
            </h2>
        </SwiperSlide>
        <SwiperSlide>
            <img src='images/banner_02.jpg' alt='banner_01' />
            <h2 className='home_banner_text'>
              <span>Pixels to Perfection </span>
              <span>Crafting Seamless Online</span>
              <span> Experiences</span>
            </h2>
        </SwiperSlide>
      </Swiper>
        {/* <Swiper
          autoplay={{
            delay: 3500,
            disableOnInteraction: true,
          }}
          effect={'fade'}
          pagination={{
            clickable: true,
          }}
          modules={[Autoplay, Pagination, EffectFade]} className="heroSwipper">
          <SwiperSlide>
            <img src='images/banner_01.jpg' alt='banner_01' />
            <h2 className='home_banner_text'>
              <span>Code, Create, Captivate </span>
              <span>Unleashing the Power of</span>
              <span> Web Development</span>
            </h2>
          </SwiperSlide>
          <SwiperSlide>
            <img src='images/banner_02.jpg' alt='banner_01' />
            <h2 className='home_banner_text'>
              <span>Pixels to Perfection </span>
              <span>Crafting Seamless Online</span>
              <span> Experiences</span>
            </h2>
          </SwiperSlide>
        </Swiper> */}
      </section>
      <section className='service_area section_padding'>
        <div className='container'>
          <SectionTitle secName="Services" />

          <h2 className='sec_title wow animate__animated animate__fadeInUp'>
            A Symphony of <span>Experience</span> in <br /><span>Every Service</span>
          </h2>
          <div className='service_flex'>
            <div className='service_column'>
              <div className='service_box wow animate__animated animate__fadeInUp'>
                <h3>.01</h3>
                <p>Static Web Development</p>
              </div>
            </div>
            <div className='service_column'>
              <div className='service_box wow animate__animated animate__fadeInUp'>
                <h3>.02</h3>
                <p>Dynamic Web Development</p>
              </div>
            </div>
            <div className='service_column'>
              <div className='service_box wow animate__animated animate__fadeInUp'>
                <h3>.03</h3>
                <p>Wordpress Development</p>
              </div>
            </div>
            <div className='service_column'>
              <div className='service_box wow animate__animated animate__fadeInUp'>
                <h3>.04</h3>
                <p>Graphic Design</p>
              </div>
            </div>
            <div className='service_column'>
              <div className='service_box wow animate__animated animate__fadeInUp'>
                <h3>.05</h3>
                <p>UI/UX Design</p>
              </div>
            </div>
          </div>

          <Link to="/services" className='redirection_link wow animate__animated animate__fadeInUp' onClick={scrollToTop}>
            <span>See All Service</span>
            <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" d="M16 4L6 14l1.41 1.41L15 7.83V28h2V7.83l7.59 7.58L26 14z" /></svg>
          </Link>
        </div>
      </section>
      <section className='about_me_area'>
        <div className='container'>
          <SectionTitle secName="About Me" />
          <h2 className='sec_title wow animate__animated animate__fadeInUp'>
            I don't just build websites or design
            interfaces; I craft <span>digital ecosystems </span>that leave a lasting <span>impression</span>.
          </h2>
        </div>
        <div className='about_me_container'>
          <div className='about_me_flex'>
            <div className='about_me_left  wow animate__animated animate__fadeInLeft'>
              <img src='images/what_we_do.jpg' alt='About Me Image' />
            </div>
            <div className='about_me_right'>
              <p className='wow animate__animated animate__fadeInRight'>
                In the world of web development, I bring ideas to life through
                meticulous coding,  creating websites that are not just functional but also visually stunning. Every line of code is crafted with precision to ensure seamless navigation and optimal performance, providing users with an immersive online journey. Where the fusion of code,  creativity, and user-centric design converges into a harmonious digital symphony. As a versatile freelancer specializing in web
                development, UX design, and graphic design, I am dedicated to transforming concepts into captivating digital experiences.
              </p>
              <Link to="/about-me" className='redirection_link wow animate__animated animate__fadeInRight' onClick={scrollToTop}>
                <span>Read More</span>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" d="M16 4L6 14l1.41 1.41L15 7.83V28h2V7.83l7.59 7.58L26 14z" /></svg>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className='what_we_do_area section_padding'>
        <div className='container'>
          <SectionTitle secName="My Work Spectrum" />

          <div className='what_we_do_flex'>
            <div className='what_we_do_left'>
              <h2 className='sec_title wow animate__animated animate__fadeInUp'>
                Professional <br />
                <span>Services</span>
              </h2>
              <p className='wow animate__animated animate__fadeInUp'>
                My area of expertise lies in transforming digital ideas into reality by combining a dynamic blend of technical expertise and artistic skill. My services include all aspect of web development, from creating user interfaces that are intuitive and visually appealing to building dependable, responsive websites with clear code.
              </p>
            </div>
            <div className='what_we_do_right'>
              <Accordion preExpanded={['a']}>
                <AccordionItem uuid="a" className='accordion__item wow animate__animated animate__fadeInUp'>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <span>.01 /</span>
                      <span>Static Web Development</span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Static web development refers to the creation of websites that consist of fixed, unchanging content. Unlike dynamic websites that retrieve data from a database and generate content on the fly
                    </p>
                    <Link to="/static-web-development" className='redirection_link' onClick={scrollToTop}>
                      <span>Read More</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" d="M16 4L6 14l1.41 1.41L15 7.83V28h2V7.83l7.59 7.58L26 14z" /></svg>
                    </Link>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid="b" className='accordion__item wow animate__animated animate__fadeInUp'>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <span>.02 /</span>
                      <span>Dynamic Web Development</span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Dynamic web development involves creating websites that generate content in real-time, responding to user interactions and input. Unlike static websites, dynamic websites can retrieve and display data from databases, enabling personalized and interactive experiences for users.
                    </p>
                    <Link to="dynamic-web-development" className='redirection_link' onClick={scrollToTop}>
                      <span>Read More</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" d="M16 4L6 14l1.41 1.41L15 7.83V28h2V7.83l7.59 7.58L26 14z" /></svg>
                    </Link>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid="c" className='accordion__item wow animate__animated animate__fadeInUp'>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <span>.03 /</span>
                      <span>Wordpress Development</span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      WordPress development refers to the process of creating and customizing websites using the WordPress content management system (CMS). WordPress is a popular and versatile platform that powers a significant portion of the internet, ranging from personal blogs to complex corporate websites and e-commerce platforms.
                    </p>
                    <Link to="wordpress-development" className='redirection_link' onClick={scrollToTop}>
                      <span>Read More</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" d="M16 4L6 14l1.41 1.41L15 7.83V28h2V7.83l7.59 7.58L26 14z" /></svg>
                    </Link>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid="d" className='accordion__item wow animate__animated animate__fadeInUp'>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <span>.04 /</span>
                      <span>UI/UX Design</span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      UI design deals with the look and feel of a product, UX design is about the overall experience, ensuring that the product is easy to use, meets users' needs, and provides a positive interaction.
                    </p>
                    <Link to="uiux-design" className='redirection_link' onClick={scrollToTop}>
                      <span>Read More</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" d="M16 4L6 14l1.41 1.41L15 7.83V28h2V7.83l7.59 7.58L26 14z" /></svg>
                    </Link>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem uuid="e" className='accordion__item wow animate__animated animate__fadeInUp'>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      <span>.05 /</span>
                      <span>Graphic Design</span>
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Graphic design plays a crucial role in effective communication, helping to convey messages, evoke emotions, and create memorable experiences. Whether it's designing a company logo, creating a poster for an event, or developing the visual components of a website, graphic designers use their artistic skills and knowledge of design principles to produce visually compelling and impactful work.
                    </p>
                    <Link to="/graphic-design" className='redirection_link' onClick={scrollToTop}>
                      <span>Read More</span>
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" d="M16 4L6 14l1.41 1.41L15 7.83V28h2V7.83l7.59 7.58L26 14z" /></svg>
                    </Link>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </section>
      <section className='portfolio_area'>
        <div className='container'>
          <SectionTitle secName="Portfolio" />
          <div className='projects_slider'>
            <Swiper pagination={{
              clickable: true,
            }} slidesPerView={3}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                  spaceBetween: 40,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                991: {
                  slidesPerView: 3,
                  spaceBetween: 40,
                }
              }}
              spaceBetween={40} modules={[Pagination]} className="projectSlider">
              <SwiperSlide><img src='images/project/Notandas_Webpage_project.png' alt='Notandas Jeweller' /></SwiperSlide>
              <SwiperSlide><img src='images/project/Dodhia_Webpage_project.png' alt='Dodhia Textile' /></SwiperSlide>
              <SwiperSlide><img src='images/project/RT_Webpage_project.png' alt='Dodhia Textile' /></SwiperSlide>
              <SwiperSlide><img src='images/project/Khalida_Toukkani_Webpage_project.png' alt='Khalida Toukkani' /></SwiperSlide>
              <SwiperSlide><img src='images/project/MPUH_Webpage_project.png' alt='Muljibhai Patel Urology Hospital' /></SwiperSlide>
              <SwiperSlide><img src='images/project/Nagarkot_Webpage_project.png' alt='Nagarkot Logistics' /></SwiperSlide>
            </Swiper>
          </div>
        </div>
      </section>
      <Marquee />
      <Contact />
      <section className='artistics_enhancement_area section_padding'>
        <div className='container'>
          <SectionTitle secName="Artistic Enhancement" />

          <div className='artistic_enhancement_flex'>
            <div className='artistic_enhancement_col'>
              <div className='artistics_enhancement_box wow animate__animated animate__fadeInLeft'>
                <img src='images/creativity.png' alt='Innovative Concepts' />
                <div className='artistics_enhancement_info'>
                  <h3>Innovative Concepts</h3>
                  <p>
                    Unleashing a realm of possibilities through innovative concepts that redefine the boundaries of creative exploration.
                  </p>
                </div>
              </div>
            </div>
            <div className='artistic_enhancement_col'>
              <div className='artistics_enhancement_box wow animate__animated animate__fadeInRight'>
                <img src='images/support.png' alt='Dedicated Client Support' />
                <div className='artistics_enhancement_info'>
                  <h3>Dedicated Client Support</h3>
                  <p>
                    At the heart of our commitment lies dedicated client support, ensuring your needs are not just met but exceeded with personalized attention and swift solutions.
                  </p>
                </div>
              </div>
            </div>
            <div className='artistic_enhancement_col'>
              <div className='artistics_enhancement_box wow animate__animated animate__fadeInLeft'>
                <img src='images/layers.png' alt='Development Excellence' />
                <div className='artistics_enhancement_info'>
                  <h3>Development Excellence</h3>
                  <p>
                    Elevating projects to their zenith, our optimal development excellence ensures a seamless fusion of innovation, precision, and top-notch expertise.
                  </p>
                </div>
              </div>
            </div>
            <div className='artistic_enhancement_col'>
              <div className='artistics_enhancement_box wow animate__animated animate__fadeInRight'>
                <img src='images/solving.png' alt='Innovative Concepts' />
                <div className='artistics_enhancement_info'>
                  <h3>Navigating Complex Issues</h3>
                  <p>
                    In the intricate landscape of challenges, we specialize in navigating complex issues with strategic finesse, offering clear paths to resolution and sustained success.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Home