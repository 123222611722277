export const sources = [
    {
        id: 1,
        category: "",
        content: "Programming Languages Behind Google Maps",
        desciption: "Google Maps has revolutionized the way we navigate our world, providing real-time information and interactive maps at our fingertips. Behind this technological marvel lies a sophisticated architecture driven by a carefully selected ensemble of programming languages. Let’s delve into the intricate web of code that powers the seamless functionality of Google Maps.",
        urlToImage: "https://www.analyticsinsight.net/wp-content/uploads/2024/01/Programming-Languages-Behind-Google-Maps-Greeshmitha.jpg",
        url: "https://www.analyticsinsight.net/programming-languages-behind-google-maps/",
        publishedAt: "January 28, 2024"
    },
    {
        id: 2,
        category: "",
        content: "How to Automatically Consume RESTful APIs in Your Frontend",
        desciption: "One of the tasks that I’ve worked on extensively in the past is designing, developing, deploying, and finally consuming numerous RESTful APIs, and I can tell you, this is one of the hardest tasks a web developer has to deal with.",
        urlToImage: "https://www.vorillaz.com/imgs/cables-wiring.jpg",
        url: "https://dev.to/vorillaz/how-to-automatically-consume-restful-apis-in-your-frontend-48ba",
        publishedAt: "January 25, 2024"
    },
    {
        id: 3,
        category: "",
        content: "10 Online Computer Programming Courses to Enroll in 2024",
        desciption: "Embarking on a journey of continuous learning is essential in the rapidly evolving field of computer programming. Stay ahead in 2024 by enrolling in these ten meticulously curated online courses, each designed to enhance your skills and keep you well informed of the latest advancements in the dynamic realm of programming. Here’s a list of 10 online computer programming courses to enroll in 2024:",
        urlToImage: "https://www.analyticsinsight.net/wp-content/uploads/2024/01/10-Online-Computer-Programming-Courses-to-Enroll-in-2024.jpg",
        url: "https://www.analyticsinsight.net/10-online-computer-programming-courses-to-enroll-in-2024/",
        publishedAt: "January 26, 2024"
    },
    {
        id: 4,
        category: "",
        content: "Python vs. R for Predictive Modeling: A Comparative Analysis",
        desciption: "Python and R are two of the most popular programming languages for data science and predictive modeling. They both have their advantages and disadvantages, depending on the context and the objectives of the project. Here is a brief comparison of Python and R for predictive modeling:",
        urlToImage: "https://www.analyticsinsight.net/wp-content/uploads/2024/01/Python-vs.-R-for-Predictive-Modeling-A-Comparative-Analysis-1.jpg",
        url: "https://www.analyticsinsight.net/python-vs-r-for-predictive-modeling-a-comparative-analysis/",
        publishedAt: "January 27, 2024"
    },
    {
        id: 5,
        category: "",
        content: "Google Certificate Courses in Cybersecurity & Business Intelligence",
        desciption: "An American multinational technology company, Google is providing a Professional Certificate in Cybersecurity & Business Intelligence.Google said on its page, 'You can now prepare for a new career in a high-growth field without any prior experience.''The Career Certificates program offers professional training designed by Google and the opportunity to connect with top employers', the company added.",
        urlToImage: "https://sightsinplus.com/wp-content/uploads/2024/01/Google-Professional-Certificate-Cybersecurity-Business-Intelligence.jpg",
        url: "https://sightsinplus.com/news/industry-news/google-certificate-courses-in-cybersecurity-business-intelligence/amp/",
        publishedAt: "January 27, 2024"
    },
    {
        id: 6,
        category: "",
        content: "What is the ideal Tech stack to build a website in 2024?",
        desciption: "While this question plagues many and myself, it is as frequently asked as it is frequently debated. Whether you’re looking to make a complete web application or your first web page, the technologies you choose to do it come with their own their pros and cons. Here I am to list my favourite’s and why.",
        urlToImage: "https://media.dev.to/cdn-cgi/image/width=1000,height=420,fit=cover,gravity=auto,format=auto/https%3A%2F%2Fdev-to-uploads.s3.amazonaws.com%2Fuploads%2Farticles%2Fkicfac27w7trh7d4td82.jpeg",
        url: "https://dev.to/jakemackie/web-development-in-2024-29d6",
        publishedAt: "January 23, 2024"
    },
    {
        id: 7,
        category: "",
        content: "The React Roadmap for 2024",
        desciption: "In this guide, I'll break down a complete roadmap that will cover all the concepts, libraries, and tools to help you become a React developer in 2024.My goal is to show you the happy path in React, navigating you away from pitfalls so you can spend your time on what really matters in React to help you reach your goals.",
        urlToImage: "https://media.dev.to/cdn-cgi/image/width=1000,height=420,fit=cover,gravity=auto,format=auto/https%3A%2F%2Fdev-to-uploads.s3.amazonaws.com%2Fuploads%2Farticles%2F2bx2jfpmgb2mobhx30dw.png",
        url: "https://dev.to/codebootcamp/the-react-roadmap-for-2024-1g24",
        publishedAt: "January 23, 2024"
    },
    {
        id: 8,
        category: "",
        content: "What is on-page optimization?",
        desciption: "On-page optimization is a crucial part of any SEO strategy. It includes optimizations made directly to the elements of a webpage to improve rankings in search engines and provide a better user experience.",
        urlToImage: "https://searchengineland.com/wp-content/seloads/2024/01/What-is-on-page-optimization-600x338.png.webp",
        url: "https://dev.to/alexanderop/how-to-write-clean-vue-components-5bl",
        publishedAt: "January 29, 2024"
    },
    {
        id: 9,
        category: "",
        content: "How to Write Clean Vue Components",
        desciption: "Writing code that's both easy to test and easy to read can be a challenge, especially with Vue components. In this blog post, I'm going to share a design idea that will make your Vue components better.This method won't speed up your code, but it will make it simpler to test and understand. Think of it as a big-picture way to improve your Vue coding style. It's going to make your life easier when you need to fix or update your components.",
        urlToImage: "https://media.dev.to/cdn-cgi/image/width=1000,height=420,fit=cover,gravity=auto,format=auto/https%3A%2F%2Fdev-to-uploads.s3.amazonaws.com%2Fuploads%2Farticles%2F4glgdfgholv1je3824c9.png",
        url: "https://searchengineland.com/what-is-on-page-optimization-436921",
        publishedAt: "January 28, 2024"
    },
    {
        id: 10,
        category: "",
        content: "10 Libraries for Machine Learning in Java",
        desciption: "Java, a versatile and widely used programming language, has made significant strides in the field of machine learning (ML) over the years. As the demand for machine learning applications continues to grow, developers are seeking robust and efficient libraries to implement ML algorithms seamlessly. In this article, we will delve into the top 10 Java libraries that empower developers to build sophisticated machine-learning models.",
        urlToImage: "https://www.analyticsinsight.net/wp-content/uploads/2024/01/10-Libraries-for-Machine-Learning-in-Java-Sravanthi.jpg",
        url: "https://www.analyticsinsight.net/10-libraries-for-machine-learning-in-java/",
        publishedAt: "January 28, 2024"
    }
]
