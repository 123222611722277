import React, {useEffect, useState} from 'react'
import Loading from '../../shared/Loading'
import Header from '../../shared/Header'
import Footer from '../../shared/Footer'
import Contact from '../../shared/Contact'
import { Link } from 'react-router-dom'

function StaticWebDevelopment() {
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      // Simulate an API call
      setTimeout(() => {
        setIsLoading(false);
      }, 3500);
    }, []);
  
    if (isLoading) {
      return <Loading  />;
    }
    return (
        <>
            <Header />
            <section className='page_banner_area'>
                <img src='images/inner_service_banner.jpg' alt='page_banner' className='banner_img_bg' />
                <div className='page_banner_content'>
                    <h2>
                        <span>Static Web</span><br />
                        Development
                    </h2>
                    <p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m21.66 10.25l-9-8a1 1 0 0 0-1.32 0l-9 8a1 1 0 0 0-.27 1.11A1 1 0 0 0 3 12h1v9a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-9h1a1 1 0 0 0 .93-.64a1 1 0 0 0-.27-1.11M13 20h-2v-3a1 1 0 0 1 2 0Zm5 0h-3v-3a3 3 0 0 0-6 0v3H6v-8h12ZM5.63 10L12 4.34L18.37 10Z" /></svg>
                        <Link to='/'>Home</Link>
                        <span className='slash'>/</span>
                        <Link to='/services'>Services</Link>
                        <span className='slash'>/</span>
                        <span>Static Web Development</span>
                    </p>
                </div>
            </section>
            <section className='all_service_page_content section_padding'>
                <div className='container'>
                    <div className='all_service_inner_image'>
                        <img src="images/inner_staticdevelopment_img.jpeg" />
                    </div>
                    <div className='all_service_inner_para'>
                        <p>
                        Static web development refers to the creation of websites that consist of fixed, unchanging content. Unlike dynamic websites that retrieve data from a database and generate content on the fly, static websites are pre-built and display the same information to all users. The content on a static site remains constant until manually updated by a developer.
                        <br />
                        <br />
                        In the realm of static web development, simplicity meets sophistication, and I am your dedicated architect crafting digital spaces that resonate with timeless elegance. Specializing in the creation of static websites, my focus lies in delivering efficient, fast-loading, and visually appealing online experiences. Embracing a meticulous approach, I design and code each element with precision, ensuring a seamless user journey and optimal performance. By leveraging the power of HTML, CSS, and perhaps a touch of JavaScript, I bring forth websites that not only convey information effectively but also exude a sense of refinement. Whether you're seeking a portfolio showcase, a business landing page, or an informational site, my expertise in static web development is geared towards providing you with a digital presence that stands the test of time. Welcome to a world where simplicity is elevated to an art form, and your static website becomes a digital masterpiece.

                        </p>
                    </div>
                </div>
            </section>
            <Contact name="innerPageAbout" />
            <Footer />
        </>
    )
}

export default StaticWebDevelopment