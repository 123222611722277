import React from 'react'
import { Link } from 'react-router-dom'
import SectionTitle from './SectionTitle';

function Contact(props) {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
    return (
        <section className={props.name + ' ' + 'home_contact_area'}>
            <div className='container'>
                <SectionTitle secName="Contact Me" />
                <div className='home_contact_content wow animate__animated animate__fadeInUp'>
                    <h3>
                        "Crafting Code, Shaping Experiences" <br />
                        Your Web Development Partner.
                    </h3>
                    <Link to='/contact-us' className='home_contact_link' onClick={scrollToTop}>Contact</Link>
                </div>
            </div>
        </section>
    )
}

export default Contact