import React from 'react'
import Header from '../shared/Header'
import Footer from '../shared/Footer'
import Marquee from '../shared/Marquee'
import Contact from '../shared/Contact'
import VerticalLines from '../shared/VerticalLines'
import { Link } from 'react-router-dom'
import Loading from '../shared/Loading'
import SectionTitle from '../shared/SectionTitle'
import { useEffect, useState } from 'react';


function About() {
        const [isLoading, setIsLoading] = useState(true);
      
        useEffect(() => {
          // Simulate an API call
          setTimeout(() => {
            setIsLoading(false);
          }, 3500);
        }, []);
      
        if (isLoading) {
          return <Loading />;
        }
    return (
        <>
            <VerticalLines />
            <Header />
            <section className='page_banner_area'>
                <img src='images/about_banner_bg.jpg' alt='page_banner' className='banner_img_bg' />
                <div className='page_banner_content'>
                    <h2 className='wow animate__animated animate__fadeInUp'><span>About</span> Me</h2>
                    <p className='wow animate__animated animate__fadeInUp animate__delay-1s'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m21.66 10.25l-9-8a1 1 0 0 0-1.32 0l-9 8a1 1 0 0 0-.27 1.11A1 1 0 0 0 3 12h1v9a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-9h1a1 1 0 0 0 .93-.64a1 1 0 0 0-.27-1.11M13 20h-2v-3a1 1 0 0 1 2 0Zm5 0h-3v-3a3 3 0 0 0-6 0v3H6v-8h12ZM5.63 10L12 4.34L18.37 10Z" /></svg>
                        <Link href='/'>Home</Link>
                        <span className='slash'>/</span>
                        <span>About Me</span>
                    </p>
                </div>
            </section>
            <section className='about_me_content'>
                <div className='container'>
                    <img src='images/about_us_image.jpeg' alt='About US Image' className='about_img wow animate__animated animate__fadeInUp' />
                    <div className='about_info'>
                        <h2 className='sec_title wow animate__animated animate__fadeInUp'>
                            Building Bridges Between <br />
                            <span>Ideas</span> and <span>Interfaces</span>
                        </h2>
                        <p className='wow animate__animated animate__fadeInUp'>
                            Hi there, Greetings from the diverse realm of digital creativity! I am a talented and driven professional with several years of cumulative experience in the web development field, now working as a freelancer. I've developed my abilities to provide my clients with outstanding outcomes. My areas of expertise are in constructing visually striking and intuitive websites, seamless user experiences, and eye-catching graphics that make an impact. My creative thinking, attention to detail, and commitment to fulfilling the needs of my clients are my greatest assets.
                            <br />
                            <br />
                            I'm committed to delivering exceptional user experiences that excite and involve users as a freelancer. I work hard to create user-friendly and aesthetically pleasing interfaces for websites and applications by having a thorough understanding of user-centered design principles. My passion lies in creating designs that are highly useful and aesthetically beautiful by incorporating user feedback, doing user research, and creating smooth user journeys. I can turn concepts into amazing user experiences since I have experience with wireframing, prototyping, and user testing.
                            <br />
                            My expertise in graphic design adds an artistic touch to every project, enhancing my technological mastery. From intriguing images to eye-catching branding.
                            <br />
                            <br />
                            As a freelancer, my strengths come from my varied skill set, meticulous attention to detail, and dedication to providing solutions that are specifically targeted to your needs. I can make your idea a reality whether you need a user-friendly interface, a responsive website, or striking graphics. Together, let's design user experiences that leave a lasting impression.
                        </p>
                    </div>
                </div>
            </section>
            <Marquee />
            <section className='technology_area'>
                <div className='container'>
                    <SectionTitle secName="Technology" />

                    <div className='technology_flex'>
                        <div className='tech_img'>
                            <img src='images/html-5.png' />
                        </div>
                        <div className='tech_img'>
                            <img src='images/css-3.png' />
                        </div>
                        <div className='tech_img'>
                            <img src='images/js.png' />
                        </div>
                        <div className='tech_img'>
                            <img src='images/jquery-logo.png' />
                        </div>
                        <div className='tech_img'>
                            <img src='images/bootstrap.png' />
                        </div>
                        <div className='tech_img'>
                            <img src='images/atom.png' />
                        </div>
                        <div className='tech_img'>
                            <img src='images/mysql.png' />
                        </div>
                        <div className='tech_img'>
                            <img src='images/wordpress.png' />
                        </div>
                        <div className='tech_img'>
                            <img src='images/php.png' />
                        </div>
                    </div>
                </div>
            </section>
            <Contact name="innerPageAbout" />
            <Footer />
        </>
    )
}

export default About