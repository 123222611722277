import React, {useEffect, useState} from 'react'
import Header from '../shared/Header'
import Footer from '../shared/Footer'
import Contact from '../shared/Contact'
import VerticalLines from '../shared/VerticalLines'
import { Link } from 'react-router-dom'
import Loading from '../shared/Loading'

function Portfolio() {

    const portfolioData = [
        {
            id: 1,
            projectName: "AYM Annual Report 2022-23",
            projectThumbnail: "AYM-AR_Webpage_project",
            projectThumbnailFormat: ".png",
            projectUrl: "https://aymsyntex.com/AR22-23/",
            info:{
                projectDescription: "",
            }
        },
        {
            id: 2,
            projectName: "Dodhia Textile",
            projectThumbnail: "Dodhia_Webpage_project",
            projectThumbnailFormat: ".png",
            projectUrl: "https://dodhia.netlify.app/",
            info:{
                projectDescription: "",
            }
        },
        {
            id: 3,
            projectName: "Khalida Toukkani",
            projectThumbnail: "Khalida_Toukkani_Webpage_project",
            projectThumbnailFormat: ".png",
            projectUrl: "https://khalidatoukkani.com/",
            info:{
                projectDescription: "",
            }
        },
        {
            id: 4,
            projectName: "Muljibhai Patel Urology Hospital",
            projectThumbnail: "MPUH_Webpage_project",
            projectThumbnailFormat: ".png",
            projectUrl: "https://swapnil2411.github.io/MUPH/",
            info:{
                projectDescription: "",
            }
        },
        {
            id: 5,
            projectName: "Nagorkot Logistics",
            projectThumbnail: "Nagarkot_Webpage_project",
            projectThumbnailFormat: ".png",
            projectUrl: "https://nagarkot.co.in/",
            info:{
                projectDescription: "",
            }
        },
        // {
        //     id: 6,
        //     projectName: "Indetrans Logistics",
        //     projectThumbnail: "Indetrans_logistics",
        //     projectThumbnailFormat: ".png",
        //     projectUrl: "",
        //     info:{
        //         projectDescription: "",
        //     }
        // },
        {
            id: 7,
            projectName: "Notandas Jewellers",
            projectThumbnail: "Notandas_Webpage_project",
            projectThumbnailFormat: ".png",
            projectUrl: "https://www.notandas.com/",
            info:{
                projectDescription: "",
            }
        },
        {
            id: 8,
            projectName: "Reversethought Creative Agency",
            projectThumbnail: "RT_Webpage_project",
            projectThumbnailFormat: ".png",
            projectUrl: "https://www.reversethought.com/",
            info:{
                projectDescription: "",
            }
        },
        {
            id: 9,
            projectName: "Reversethought Creative Agency - Campaign",
            projectThumbnail: "RT_Campaign_project",
            projectThumbnailFormat: ".png",
            projectUrl: "https://reversethought.com/campaigns/webservices/",
            info:{
                projectDescription: "",
            }
        },
        {
            id: 10,
            projectName: "AllCargo Gati - Landing Page",
            projectThumbnail: "Allcargogati_Webpage_project",
            projectThumbnailFormat: ".png",
            projectUrl: "",
            info:{
                projectDescription: "",
            }
        },
        {
            id: 11,
            projectName: "Adtiya Birla Health Insurance - Campaign",
            projectThumbnail: "Aditya_birla_health_insurance_campaign",
            projectThumbnailFormat: ".png",
            projectUrl: "https://swapnil2411.github.io/adityBirlaCapital/",
            info:{
                projectDescription: "",
            }
        },
        {
            id: 12,
            projectName: "Concept Cosilio",
            projectThumbnail: "Conceptconsilio_Webpage_project",
            projectThumbnailFormat: ".png",
            projectUrl: "",
            info:{
                projectDescription: "",
            }
        },
        {
            id: 13,
            projectName: "Adtiya Birla Health Insurance - Campaign",
            projectThumbnail: "Aditya_birla_health_insurance_campaign_02",
            projectThumbnailFormat: ".png",
            projectUrl: "https://swapnil2411.github.io/adityBirlaCapital/",
            info:{
                projectDescription: "",
            }
        },
        
    ]

    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      // Simulate an API call
      setTimeout(() => {
        setIsLoading(false);
      }, 3500);
    }, []);
  
    if (isLoading) {
      return <Loading  />;
    }

    return (
        <>
            <VerticalLines />
            <Header />
            <section className='page_banner_area'>
                <img src='images/service_banner_bg.jpg' alt='page_banner' className='banner_img_bg' />
                <div className='page_banner_content'>
                    <h2>
                        <span>Portfolio</span>
                    </h2>
                    <p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m21.66 10.25l-9-8a1 1 0 0 0-1.32 0l-9 8a1 1 0 0 0-.27 1.11A1 1 0 0 0 3 12h1v9a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-9h1a1 1 0 0 0 .93-.64a1 1 0 0 0-.27-1.11M13 20h-2v-3a1 1 0 0 1 2 0Zm5 0h-3v-3a3 3 0 0 0-6 0v3H6v-8h12ZM5.63 10L12 4.34L18.37 10Z" /></svg>
                        <Link to='/'>Home</Link>
                        <span className='slash'>/</span>
                        <span>Portfolio</span>
                    </p>
                </div>
            </section>
            <section className='portfolio_showcase section_padding'>
                <div className='container'>
                    <div className='portfolio_flex'>
                        {
                            portfolioData.map((item) => {
                                return (
                                    <Link to={item.projectUrl} className='portfolio_col' key={item.id} target='_blank'>
                                        <div className='portfolio_proj_content'>
                                            <div className='portfolio_img'>
                                                <img src={'images/project/'+ item.projectThumbnail + item.projectThumbnailFormat} alt='' />
                                            </div>
                                            <div className='portfolio_proj_info'>
                                                <h4 className='portfolio_proj_name'>{item.projectName}</h4>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <Contact name="innerPageAbout" />
            <Footer />
        </>
    )
}

export default Portfolio