import React, {useState, useRef, useEffect} from 'react'
import Header from '../shared/Header'
import Footer from '../shared/Footer'
import Multiselect from 'multiselect-react-dropdown'
import VerticalLines from '../shared/VerticalLines'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom'
import Loading from '../shared/Loading'

function ContactUs() {
    
    const multiselectRef = useRef();
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        subject: '',
        service: [],
        message: '',
    });

    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      // Simulate an API call
      setTimeout(() => {
        setIsLoading(false);
      }, 3500);
    }, []);
  
    if (isLoading) {
      return <Loading  />;
    }

  

const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    // console.log(formData)
};

const handleSelect = (selected) => {
    setSelectedOptions(selected);
    const selectedKeys = selected.map((option) => option.key).join(', ');
    // console.log('Selected keys:', selectedKeys);
    setFormData({...formData, service: selectedKeys})
  };

const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData)
    try {
        const response = await fetch('https://email-repo.onrender.com/contact', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            formData
          }),
        });
  
        const result = await response.json();
        // console.log(result.message);
        setFormData({
            name: '',
            email: '',
            subject: '',
            service: [],
            message: '',
        })

        // Clear Multiselect chips
        multiselectRef.current.resetSelectedValues();
        toast.success(result.message, {
            position: "top-center"
        })

        
      } catch (error) {
        console.log('Error sending the contact form:', error);
        toast.error('An error occurred while sending the email. Please try again later.',{
            position: "top-center"
        });
    }

    
  };
    return (
        <>
            <VerticalLines />
            <Header />
            <section className='page_banner_area'>
                <img src='images/contact_banner_bg.jpg' alt='page_banner' className='banner_img_bg' />
                <div className='page_banner_content'>
                    <h2>
                        <span>Contact </span>Us
                    </h2>
                    <p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m21.66 10.25l-9-8a1 1 0 0 0-1.32 0l-9 8a1 1 0 0 0-.27 1.11A1 1 0 0 0 3 12h1v9a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-9h1a1 1 0 0 0 .93-.64a1 1 0 0 0-.27-1.11M13 20h-2v-3a1 1 0 0 1 2 0Zm5 0h-3v-3a3 3 0 0 0-6 0v3H6v-8h12ZM5.63 10L12 4.34L18.37 10Z" /></svg>
                        <Link to='/'>Home</Link>
                        <span className='slash'>/</span>
                        <span>Contact Us</span>
                    </p>
                </div>
            </section>
            <section className='top_footer section_padding'>
                <div className='container'>
                    <div className='top_footer_flex'>
                        <div className='top_footer_left'>
                            <h2><span>Get in </span>touch</h2>
                            <p>
                                As a freelance web developer, UX designer, and graphic artist, I embark on a mission to transform ideas into visually stunning and functionally seamless realities. With a foundation in web development, I code with precision, bringing websites to life that not only meet but exceed modern standards.
                            </p>
                            <div className='top_footer_contact_info'>
                                <div className='contact_details'>
                                    <h3>Contact Info</h3>
                                    <div className='mobile_email'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M16.75 2h-10c-1.103 0-2 .897-2 2v16c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V4c0-1.103-.897-2-2-2m-10 18V4h10l.002 16z" /><circle cx="11.75" cy="18" r="1" fill="currentColor" /></svg>
                                        <Link to='tel:+91 8828181399'>+91 8828181399</Link>
                                    </div>
                                    <div className='mobile_email'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2zm-2 0l-8 4.99L4 6zm0 12H4V8l8 5l8-5z" /></svg>
                                        <Link to='mailto:swapnildarge65@gmail.com'>swapnildarge65@gmail.com</Link>
                                    </div>
                                </div>
                                <div className='address_info'>
                                    <h3>Address</h3>
                                    <p>
                                        14/1406, City View Tower, <br />
                                        S.N Path, Lower Parel (West), <br />
                                        Mumbai - 400013
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className='top_footer_right'>
                            <h2>Send Message</h2>
                            <form onSubmit={handleSubmit}>
                                <div className='form_element'>
                                    <input type='text' placeholder='Full Name' name='name' id='name' value={formData.name} onChange={handleChange} />
                                </div>
                                <div className='form_element'>
                                    <input type='email' placeholder='Email' name='email' id='email' value={formData.email} onChange={handleChange} />
                                </div>
                                <div className='form_element'>
                                    <input type='text' placeholder='Subject' name='subject' id='subject' value={formData.subject} onChange={handleChange} />
                                </div>
                                <div className='form_element'>
                                    <Multiselect
                                        displayValue="key"
                                        id="css_custom"
                                        onKeyPressFn={function noRefCheck() { }}
                                        onRemove={function noRefCheck() { }}
                                        onSearch={function noRefCheck() { }}
                                        onSelect={handleSelect}
                                        options={[
                                            {
                                                cat: 'service',
                                                key: 'Static Web Development'
                                            },
                                            {
                                                cat: 'service',
                                                key: 'Dynamic Web Development'
                                            },
                                            {
                                                cat: 'service',
                                                key: 'Wordpress Development'
                                            },
                                            {
                                                cat: 'service',
                                                key: 'UI/UX Design'
                                            },
                                            {
                                                cat: 'service',
                                                key: 'Graphic Design'
                                            },
                                        ]}
                                        placeholder="Select Services"
                                        value={formData.service}
                                        style={{
                                            chips: {
                                                background: '#EF644C'
                                            },
                                            searchBox: {
                                                border: 'none',
                                                'borderBottom': '1px solid blue',
                                                'borderRadius': '0px'
                                            }
                                        }}
                                        ref={multiselectRef}
                                    />
                                </div>
                                <div className='form_element'>
                                    <textarea type='text' placeholder='Message' name='message' id='message' rows={5} value={formData.message} onChange={handleChange} />
                                </div>
                                <button type='submit' className='redirection_link'>
                                    <span>Send Message</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32"><path fill="currentColor" d="M16 4L6 14l1.41 1.41L15 7.83V28h2V7.83l7.59 7.58L26 14z" /></svg>
                                </button>
                            </form>
                        </div>
                    </div>
                    <img src='images/contact_us_image.jpeg' alt='Contact US Image' className='contactus_img' />
                </div>

            </section>
            <Footer />
        </>
    )
}

export default ContactUs