import React, { useState, useEffect } from 'react'
import Header from '../shared/Header'
import Footer from '../shared/Footer'
import Contact from '../shared/Contact'
import { sources } from '../BlogsData/mockData'
import VerticalLines from '../shared/VerticalLines'
import { Link } from 'react-router-dom'
import Loading from '../shared/Loading'

function Blogs() {

    const [blogData, setBlogData] = useState(sources)
    // console.log(blogData)
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      // Simulate an API call
      setTimeout(() => {
        setIsLoading(false);
      }, 3500);
    }, []);
  
    if (isLoading) {
      return <Loading  />;
    }

    // const data = []
    // const blogsFetch = async() =>{
    //     const url = 'https://newsapi.org/v2/top-headlines?country=in&category=technology&apiKey=c2752a391bf6401eb0fedf95fdfbfe0e';
    //     // const options = {
    //     //     method: 'GET',
    //     //     headers: {
    //     //         'X-Api-Key': 'c2752a391bf6401eb0fedf95fdfbfe0e',
    //     //     }
    //     // };
    //     try {
    //         const response = await fetch(url);
    //         const result = await response.json();
    //         const data = result?.articles;
    //         console.log(data);
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    // blogsFetch();


    return (
        <>
            <VerticalLines />
            <Header />
            <section className='page_banner_area'>
                <img src='images/contact_banner_bg.jpg' alt='page_banner' className='banner_img_bg' />
                <div className='page_banner_content'>
                    <h2>
                        <span>Blogs</span>
                    </h2>
                    <p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m21.66 10.25l-9-8a1 1 0 0 0-1.32 0l-9 8a1 1 0 0 0-.27 1.11A1 1 0 0 0 3 12h1v9a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-9h1a1 1 0 0 0 .93-.64a1 1 0 0 0-.27-1.11M13 20h-2v-3a1 1 0 0 1 2 0Zm5 0h-3v-3a3 3 0 0 0-6 0v3H6v-8h12ZM5.63 10L12 4.34L18.37 10Z" /></svg>
                        <Link to='/'>Home</Link>
                        <span className='slash'>/</span>
                        <span>Blogs</span>
                    </p>
                </div>
            </section>
            <section className='section_padding blogs_listing'>
                {/* <h3>Coming Soon...</h3> */}
                <div className='container'>
                    <div className='blogs_flex'>
                        {
                            blogData.map(blogItem => {
                                return (
                                    <Link to={blogItem.url} className='blog_column'>
                                        <div className='blog_img'>
                                            <img src={blogItem?.urlToImage} alt={blogItem?.title} />
                                        </div>
                                        <span className='publish_date'>{blogItem.publishedAt}</span>
                                        <h2 className='blog_title' title={blogItem.content}>{blogItem.content}</h2>
                                        <p className='blog_para'>{blogItem.desciption}</p>
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <Contact name="innerPageAbout" />
            <Footer />
        </>
    )
}

export default Blogs