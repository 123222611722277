import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './pages/Home';
import About from './pages/About';
import Services from './pages/Services';
import StaticWebDevelopment from './pages/Service/StaticWebDevelopment';
import DynamicWebDevelopment from './pages/Service/DynamicWebDevelopment';
import WordpressDevelopment from './pages/Service/WordpressDevelopment';
import UiUxDesign from './pages/Service/UiUxDesign';
import GraphicDesign from './pages/Service/GraphicDesign';
import Portfolio from './pages/Portfolio';
import ContactUs from './pages/ContactUs';
import Blogs from './pages/Blogs';




function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
            <Route index path="/" element={<Home />} />
            <Route path="/about-me" element={<About />}/>
            <Route path="/services" element={<Services />}/>
            <Route path="/static-web-development" element={<StaticWebDevelopment/>} />
            <Route path="/dynamic-web-development" element={<DynamicWebDevelopment/>} />
            <Route path="/wordpress-development" element={<WordpressDevelopment/>} />
            <Route path="/uiux-design" element={<UiUxDesign/>} />
            <Route path="/graphic-design" element={<GraphicDesign/>} />
            <Route path="/portfolio" element={<Portfolio/>} />
            <Route path="/contact-us" element={<ContactUs/>} />
            <Route path="/blogs" element={<Blogs/>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
