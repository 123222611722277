import React, {useEffect, useState} from 'react'
import Loading from '../../shared/Loading'
import Header from '../../shared/Header'
import Footer from '../../shared/Footer'
import Contact from '../../shared/Contact'
import { Link } from 'react-router-dom'

function GraphicDesign() {
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      // Simulate an API call
      setTimeout(() => {
        setIsLoading(false);
      }, 3500);
    }, []);
  
    if (isLoading) {
      return <Loading  />;
    }
    return (
        <>
            <Header />
            <section className='page_banner_area'>
                <img src='images/inner_service_banner.jpg' alt='page_banner' className='banner_img_bg' />
                <div className='page_banner_content'>
                    <h2>
                        <span>Graphic</span> Design
                    </h2>
                    <p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m21.66 10.25l-9-8a1 1 0 0 0-1.32 0l-9 8a1 1 0 0 0-.27 1.11A1 1 0 0 0 3 12h1v9a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-9h1a1 1 0 0 0 .93-.64a1 1 0 0 0-.27-1.11M13 20h-2v-3a1 1 0 0 1 2 0Zm5 0h-3v-3a3 3 0 0 0-6 0v3H6v-8h12ZM5.63 10L12 4.34L18.37 10Z" /></svg>
                        <Link to='/'>Home</Link>
                        <span className='slash'>/</span>
                        <Link to='/services'>Services</Link>
                        <span className='slash'>/</span>
                        <span>Graphic Design</span>
                    </p>
                </div>
            </section>
            <section className='all_service_page_content section_padding'>
                <div className='container'>
                    <div className='all_service_inner_image'>
                        <img src="images/inner_graphic_design_img.jpeg" />
                    </div>
                    <div className='all_service_inner_para'>
                        <p>
                            Graphic design plays a crucial role in effective communication, helping to convey messages, evoke emotions, and create memorable experiences. Whether it's designing a company logo, creating a poster for an event, or developing the visual components of a website, graphic designers use their artistic skills and knowledge of design principles to produce visually compelling and impactful work.
                            <br />
                            <br />
                            Graphic design is the artful fusion of creativity and communication, where visual elements harmonize to convey messages, evoke emotions, and leave a lasting impression. Graphic designers, the architects of visual storytelling, wield a palette of typography, imagery, color, and layout to craft designs that transcend mere aesthetics—they communicate ideas. From the meticulous selection of fonts that convey tone to the strategic arrangement of elements on a page or screen, graphic designers bring order to chaos, ensuring visual clarity and impact. Whether it's creating a brand's visual identity, designing print materials like brochures and posters, shaping the digital aesthetics of websites and social media, or infusing custom illustrations into the mix, graphic design is a versatile and ever-evolving discipline. It's about capturing attention, guiding the viewer's eye, and, ultimately, creating a visual language that resonates. In a world saturated with information, graphic design stands as a visual storyteller, transforming concepts into captivating visuals that transcend the boundaries of language and leave an indelible mark on the viewer's mind.
                        </p>
                    </div>
                </div>
            </section>
            <Contact name="innerPageAbout" />
            <Footer />
        </>
    )
}

export default GraphicDesign