import React from 'react'

function SectionTitle(props) {
    return (
        <>
            <h2 className='sec_heading wow animate__animated animate__fadeInUp'>
                <span>{props.secName}</span>
                <img src='images/title_vector.png' alt='heading_vector' />
            </h2>
        </>
    )
}

export default SectionTitle