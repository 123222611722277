import React from 'react'

function Loading() {
  return (
    <div className="loader-container">
      <div className='loader_img'>
        <img src='images/logo.png' alt='Swapnil Darge' />
      </div>
      <div className='loader_text'>
        <span>Innovative</span>
        <span>Responsive</span>
        <span>Dynamic</span>
      </div>
    </div>
  )
}

export default Loading