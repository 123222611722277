import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom';
function Header() {
    
    const [isOpened, setIsOpened] = useState(false);

    // Sticky Menu Area
    useEffect(() => {
        window.addEventListener('scroll', isSticky);
        return () => {
            window.removeEventListener('scroll', isSticky);
        };
    });

    const sidebarMenu = () =>{
        setIsOpened((isOpened) => !isOpened)
    }

    const closeSidebarMenu = () => {
        setIsOpened(false)
    }


    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
        const header = document.querySelector('header');
        const scrollTop = window.scrollY;
        scrollTop >= 150 ? header.classList.add('is-sticky') : header.classList.remove('is-sticky');
    };

    const scrollToTop = () => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    };
  return (
    <header>
            <div className='container'>
                <div className='navbar'>
                    <div className='left_area'>
                        <div className='logo'>
                            <Link to="/">
                                <img src='images/logo.png' />
                            </Link>
                        </div>
                    </div>
                    <div className='center_area'>
                        <nav>
                            <ul>
                                <li>
                                    <Link to="/" onClick={scrollToTop}>Home</Link>
                                </li>
                                <li>
                                    <Link to="/about-me" onClick={scrollToTop}>About Me</Link>
                                </li>
                                <li>
                                    <Link to="/services" onClick={scrollToTop}>Services</Link>
                                </li>
                                <li>
                                    <Link to="/portfolio" onClick={scrollToTop}>Portfolio</Link>
                                </li>
                                <li>
                                    <Link to="/blogs" onClick={scrollToTop}>Blogs</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us" onClick={scrollToTop}>Contact</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>

                    <div className='right_area'>
                        <div className='hamburger' onClick={sidebarMenu}>
                            <span className='hamburger_block'></span>
                        </div>
                        <div className={isOpened ? 'sidebar_menu show_sidebar' : 'sidebar_menu'}>
                            <div className='close_sidebar' onClick={closeSidebarMenu}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"/></svg>
                            </div>
                            <div className='sidebar_logo'>
                                <Link to='/'>
                                    <img src='images/logo.png' />
                                </Link>
                            </div>
                            <div className='sidebar_info'>
                                <h2>
                                    <span>Hello guys, my name is</span> <br />
                                    SWAPNIL DARGE
                                </h2>
                                <p>
                                The tech industry is dynamic, with new languages, frameworks, and tools emerging regularly.<br />
                                Developing websites or web applications allows me to build something tangible. Witnessing the transformation of lines of code into a functional, user-friendly product can be immensely rewarding.
                                </p>
                            </div>
                            <div className='quick_links'>
                                <h3>Quick Links</h3>
                                <ul>
                                    <li>
                                        <Link to="/" onClick={scrollToTop}><span>.01</span>Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/about-me" onClick={scrollToTop}><span>.02</span>About Me</Link>
                                    </li>
                                    <li>
                                        <Link to="/services" onClick={scrollToTop}><span>.03</span>Services</Link>
                                    </li>
                                    <li>
                                        <Link to="/portfolio" onClick={scrollToTop}><span>.04</span>Portfolio</Link>
                                    </li>
                                    <li>
                                        <Link to="/blogs" onClick={scrollToTop}><span>.05</span>Blogs</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact-us" onClick={scrollToTop}><span>.06</span>Contact</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className='social_links'>
                                <ul>
                                    <li><Link to="https://wa.link/5mvgtx">Wa.</Link></li>
                                    <li><Link to="https://www.linkedin.com/in/swapnil-darge-942889189">Li.</Link></li>
                                    <li><Link to="">In.</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
  )
}

export default Header