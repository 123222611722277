import React, {useEffect, useState} from 'react'
import Loading from '../../shared/Loading'
import Header from '../../shared/Header'
import Footer from '../../shared/Footer'
import Contact from '../../shared/Contact'
import { Link } from 'react-router-dom'

function UiUxDesign() {
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      // Simulate an API call
      setTimeout(() => {
        setIsLoading(false);
      }, 3500);
    }, []);
  
    if (isLoading) {
      return <Loading  />;
    }
    return (
        <>
            <Header />
            <section className='page_banner_area'>
                <img src='images/inner_service_banner.jpg' alt='page_banner' className='banner_img_bg' />
                <div className='page_banner_content'>
                    <h2>
                        <span>UI/UX</span> Design
                    </h2>
                    <p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m21.66 10.25l-9-8a1 1 0 0 0-1.32 0l-9 8a1 1 0 0 0-.27 1.11A1 1 0 0 0 3 12h1v9a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-9h1a1 1 0 0 0 .93-.64a1 1 0 0 0-.27-1.11M13 20h-2v-3a1 1 0 0 1 2 0Zm5 0h-3v-3a3 3 0 0 0-6 0v3H6v-8h12ZM5.63 10L12 4.34L18.37 10Z" /></svg>
                        <Link to='/'>Home</Link>
                        <span className='slash'>/</span>
                        <Link to='/services'>Services</Link>
                        <span className='slash'>/</span>
                        <span>UI/UX Design</span>
                    </p>
                </div>
            </section>
            <section className='all_service_page_content section_padding'>
                <div className='container'>
                    <div className='all_service_inner_image'>
                        <img src="images/inner_uiux_img.jpeg" />
                    </div>
                    <div className='all_service_inner_para'>
                        <p>
                            UI design deals with the look and feel of a product, UX design is about the overall experience, ensuring that the product is easy to use, meets users' needs, and provides a positive interaction. Both UI and UX design are crucial for the success of digital products, and effective collaboration between UI and UX designers leads to well-designed and user-friendly experiences.
                            <br />
                            <br />
                            UI/UX design is a dynamic and interdisciplinary approach to crafting digital experiences that seamlessly blend form and function. User Interface (UI) design is the artistic facet, focusing on the visual elements that users directly interact with—ranging from buttons and icons to color schemes and typography. The aim is to create an aesthetically pleasing and coherent interface that not only reflects the brand's identity but also ensures an intuitive and engaging user experience. On the other hand, User Experience (UX) design delves deeper into the holistic journey a user undertakes with a product. It involves understanding user behaviors, needs, and motivations, enabling the creation of a fluid and enjoyable interaction. Through user research, wireframing, prototyping, and continuous iteration, UX designers refine the overall experience, emphasizing usability, accessibility, and user satisfaction. UI/UX design, at its core, is a symbiotic relationship where visual appeal meets user-centric functionality, resulting in digital products that captivate, delight, and fulfill the needs of the end-users.

                        </p>
                    </div>
                </div>
            </section>
            <Contact name="innerPageAbout" />
            <Footer />
        </>
    )
}

export default UiUxDesign