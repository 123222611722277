import React, {useEffect, useState} from 'react'
import Loading from '../../shared/Loading'
import Header from '../../shared/Header'
import Footer from '../../shared/Footer'
import Contact from '../../shared/Contact'
import { Link } from 'react-router-dom'

function WordpressDevelopment() {
    const [isLoading, setIsLoading] = useState(true);
  
    useEffect(() => {
      // Simulate an API call
      setTimeout(() => {
        setIsLoading(false);
      }, 3500);
    }, []);
  
    if (isLoading) {
      return <Loading  />;
    }
    return (
        <>
            <Header />
            <section className='page_banner_area'>
                <img src='images/inner_service_banner.jpg' alt='page_banner' className='banner_img_bg' />
                <div className='page_banner_content'>
                    <h2>
                        <span>WordPress</span><br />
                        Development
                    </h2>
                    <p>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path fill="currentColor" d="m21.66 10.25l-9-8a1 1 0 0 0-1.32 0l-9 8a1 1 0 0 0-.27 1.11A1 1 0 0 0 3 12h1v9a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-9h1a1 1 0 0 0 .93-.64a1 1 0 0 0-.27-1.11M13 20h-2v-3a1 1 0 0 1 2 0Zm5 0h-3v-3a3 3 0 0 0-6 0v3H6v-8h12ZM5.63 10L12 4.34L18.37 10Z" /></svg>
                        <Link to='/'>Home</Link>
                        <span className='slash'>/</span>
                        <Link to='/services'>Services</Link>
                        <span className='slash'>/</span>
                        <span>Wordpress Development</span>
                    </p>
                </div>
            </section>
            <section className='all_service_page_content section_padding'>
                <div className='container'>
                    <div className='all_service_inner_image'>
                        <img src="images/inner_dynamicdevelopment_img.jpeg" />
                    </div>
                    <div className='all_service_inner_para'>
                        <p>
                            WordPress development refers to the process of creating and customizing websites using the WordPress content management system (CMS). WordPress is a popular and versatile platform that powers a significant portion of the internet, ranging from personal blogs to complex corporate websites and e-commerce platforms. It is renowned for its user-friendly interface, extensive plugin ecosystem, and robust community support.
                            <br />
                            <br />
                            WordPress development is the art of creating dynamic and feature-rich websites using the versatile WordPress content management system (CMS). At the core of this development process is a user-friendly interface that empowers both developers and content creators to effortlessly manage digital content. WordPress provides a vast array of themes, allowing developers to craft visually stunning websites or create custom themes to align with unique design preferences. The extensibility of WordPress is further amplified through its plugin ecosystem, offering a wide range of functionalities from e-commerce solutions to SEO optimization tools. This platform's adaptability shines as developers can tailor websites to be responsive across diverse devices, ensuring an optimal user experience. WordPress development is not only about creating visually appealing sites but also about leveraging its SEO-friendly features, making it easier for websites to climb search engine rankings. With an active and supportive community, ongoing updates, and a commitment to open-source principles, WordPress stands as a powerhouse for website development, catering to bloggers, businesses, and enterprises alike, seeking scalable and customizable online solutions.

                        </p>
                    </div>
                </div>
            </section>
            <Contact name="innerPageAbout" />
            <Footer />
        </>
    )
}

export default WordpressDevelopment