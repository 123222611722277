import React from 'react'

function Marquee() {
    return (
        <section className="marquee-wrapper section_padding">
            <div className="container">
                <div className="marquee-block">
                    <div className="marquee-inner to-left">
                        <span>
                            <div className="marquee-item">
                                <p className="text-white">Designing Tomorrow's Interfaces Today</p>
                            </div>
                            <div className="marquee-item">
                                <img src='images/digital-drawing.png' alt='Digital Interface' />
                            </div>
                            <div className="marquee-item">
                                <p className="text-white">Your Vision, My Code</p>
                            </div>
                            <div className="marquee-item">
                                <img src='images/html.png' alt='Coding' />
                            </div>
                            <div className="marquee-item">
                                <p className="text-white">Responsive Designs, Responsive Results</p>
                            </div>
                            <div className="marquee-item">
                                <img src='images/responsive.png' alt='Responsive' />
                            </div>
                        </span>
                        <span>
                            <div className="marquee-item">
                                <p className="text-white">Designing Tomorrow's Interfaces Today</p>
                            </div>
                            <div className="marquee-item">
                                <img src='images/digital-drawing.png' alt='Digital Interface' />
                            </div>
                            <div className="marquee-item">
                                <p className="text-white">Your Vision, My Code</p>
                            </div>
                            <div className="marquee-item">
                                <img src='images/html.png' alt='Coding' />
                            </div>
                            <div className="marquee-item">
                                <p className="text-white">Responsive Designs, Responsive Results</p>
                            </div>
                            <div className="marquee-item">
                                <img src='images/responsive.png' alt='Responsive' />
                            </div>
                        </span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Marquee